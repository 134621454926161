import React, { useState } from "react";

export const LoginForm = () => {
  return (
    <div className="">
      <div className="w-full flex flex-col justify-end items-end">
        <div className="flex justify-end text-2xl py-5 font-bold">
          لا يمكنك استخدام الموقع
        </div>
      </div>
    </div>
  );
};
